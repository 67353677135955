import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(localStorage.getItem("token") ? true : false);
  public redirectUrl: string = "";
  errorMessages = [];
  profileStatus = 0;

  constructor(private router: Router, private sharedService: SharedService) { }

  public userLoggedIn(tokenresponse: any) {
    var decoded = JSON.stringify(tokenresponse);
    let obj = JSON.parse(decoded);
    localStorage["token"] = obj.token;
    localStorage["refreshToken"] = obj.refreshToken;
    localStorage["userRole"] = obj.role;
    localStorage["userId"] = obj.id;
    localStorage["tokenGuId"] = obj.guidId;
    localStorage["name"] = obj.name;
    localStorage["expiredTime"] = obj.expiredTime;
    localStorage["guidId"] = obj.guidId;
    localStorage["emailId"] = obj.emailId;
    this.isUserLoggedIn.next(true);
  }

  public userLoggedOut() {
    localStorage.clear();
    this.isUserLoggedIn.next(false);
  }

}
