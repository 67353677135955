import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/before-login/login/login.component';
import { DynamicFormsModule } from './components/after-login/forms/dynamic-forms.module';
import { ValidationSummaryComponent } from './components/çommon/validation-summary/validation-summary.component';
import { ErrorComponent } from './components/shared-component/error/error.component';
import { HeaderComponent } from './components/shared-component/header/header.component';
import { FooterComponent } from './components/shared-component/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { AuthGuard } from './auth/auth.guard';
import { RoleGuard } from './auth/role.guard';
import { SidebarComponent } from './components/shared-component/sidebar/sidebar.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ValidationSummaryComponent,
    ErrorComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    SidebarComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DynamicFormsModule,
    ReactiveFormsModule
  ],
  providers: [AuthGuard, RoleGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
