import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicFormsComponent } from './dynamic-forms.component';
import { ListFormsComponent } from './list-forms/list-forms.component';
import { ManageFormComponent } from './list-forms/manage-form/manage-form.component';
import { DynamicFormsRoutingModule } from './dynamic-forms-routing.module';
import { FormsModule } from '@angular/forms'


@NgModule({
  declarations: [
    DynamicFormsComponent,
    ListFormsComponent,
    ManageFormComponent
  ],
  imports: [
    CommonModule,
    DynamicFormsRoutingModule,
    FormsModule
  ]
})
export class DynamicFormsModule { }
