<footer class="bg-light mt-auto">
  <div class="legal-line">
    <div class="copy-right">
      © {{currentYear}}  Agenzee, LLC All rights reserved.
      <a target="_blank" href="https://agenzee.com/privacy-policy/" class="privacy">Privacy Policy</a>
      <a target="_blank" href="https://agenzee.com/terms-conditions/" class="privacy">Terms & Conditions</a>
    </div>
    <div class="clear"></div>
  </div>
</footer>
