import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @ViewChild('sideBar') sideBarEle: ElementRef<HTMLElement> | undefined;
  @Output() sideNvValue = new EventEmitter<any>();
  manageCarriersUrl = "manage/carriers"
  isAdmin?: boolean = false;
  // darkScrollbarOptions: MCustomScrollbar.CustomScrollbarOptions = { axis: 'y', theme: 'light', setHeight: 'calc(100vh - 92px)' };

  hideSideNav: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleSideNav() {
    this.hideSideNav = !this.hideSideNav;
    this.sideNvValue.emit(this.hideSideNav);
    sessionStorage.setItem('collapseValue', JSON.stringify(this.hideSideNav));
  }

  onResize(event) {
    if (event.target.innerWidth <= 1366 && this.hideSideNav == false) {
      this.toggleSideNav();
    }
    else if (event.target.innerWidth > 1366 && this.hideSideNav == true) {
      this.toggleSideNav();
    }
  }

}
