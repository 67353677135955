<div class="side-bar-menu">
    <ul class="side-bar-list">
    <li class="side-bar-item active" routerLinkActive="active">
      <a class="side-bar-link task-menu" href="javascript:void(0)" title="{{hideSideNav? 'PDF-Forms':''}}"
        routerLink="/forms/list">
        <div class="left-part">
          <span class="side-bar-icon">
            <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.99997 15.8443C6.22689 15.6891 6.47016 15.5737 6.72977 15.4981C6.98939 15.4224 7.26536 15.3846 7.55768 15.3846H8.1923V3.99998H7.75C7.26922 3.99998 6.85735 4.17145 6.5144 4.5144C6.17145 4.85735 5.99997 5.26922 5.99997 5.75V15.8443ZM9.69225 15.3846H18V4.3077C18 4.21795 17.9711 4.14423 17.9134 4.08653C17.8557 4.02883 17.782 3.99998 17.6922 3.99998H9.69225V15.3846ZM7.55768 21.5C6.71281 21.5 5.99198 21.2016 5.3952 20.6048C4.7984 20.008 4.5 19.2871 4.5 18.4423V5.75C4.5 4.84743 4.81602 4.08013 5.44807 3.44808C6.08012 2.81603 6.84743 2.5 7.75 2.5H17.6922C18.191 2.5 18.6169 2.67661 18.9701 3.02982C19.3233 3.38302 19.5 3.80898 19.5 4.3077V16.4481C19.5 16.5686 19.4538 16.6766 19.3615 16.7721C19.2692 16.8676 19.1198 16.9596 18.9134 17.048C18.6224 17.1519 18.3878 17.3282 18.2095 17.5769C18.0313 17.8256 17.9422 18.1141 17.9422 18.4423C17.9422 18.7641 18.0297 19.0519 18.2047 19.3058C18.3797 19.5596 18.6128 19.7365 18.9038 19.8365C19.0922 19.9109 19.2387 20.0147 19.3432 20.1481C19.4477 20.2814 19.5 20.4256 19.5 20.5807V20.7346C19.5 20.9474 19.4281 21.1282 19.2845 21.2769C19.141 21.4256 18.9628 21.5 18.75 21.5H7.55768ZM7.55768 20H16.9019C16.757 19.7731 16.6442 19.5324 16.5634 19.2779C16.4827 19.0234 16.4423 18.7448 16.4423 18.4423C16.4423 18.1564 16.4801 17.882 16.5558 17.6192C16.6314 17.3564 16.7468 17.1115 16.9019 16.8846H7.55768C7.11153 16.8846 6.74037 17.0368 6.4442 17.3413C6.14805 17.6458 5.99997 18.0128 5.99997 18.4423C5.99997 18.8884 6.14805 19.2596 6.4442 19.5558C6.74037 19.8519 7.11153 20 7.55768 20Z"
                fill="#424242" />
            </svg> -->
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.91491 13.8845H6.03041C6.28091 13.8845 6.49099 13.7998 6.66066 13.6303C6.83016 13.4608 6.91491 13.2507 6.91491 13V11.8845C6.91491 11.6338 6.83016 11.4237 6.66066 11.2542C6.49099 11.0847 6.28091 11 6.03041 11H4.47266C4.35466 11 4.25141 11.0442 4.16291 11.1327C4.07458 11.2211 4.03041 11.3242 4.03041 11.4422V15.4422C4.03041 15.5602 4.07458 15.6634 4.16291 15.7518C4.25141 15.8403 4.35466 15.8845 4.47266 15.8845C4.59066 15.8845 4.69383 15.8403 4.78216 15.7518C4.87066 15.6634 4.91491 15.5602 4.91491 15.4422V13.8845ZM4.91491 13V11.8845H6.03041V13H4.91491ZM9.89566 15.8845C10.1463 15.8845 10.3564 15.7997 10.5259 15.6302C10.6956 15.4607 10.7804 15.2507 10.7804 15V11.8845C10.7804 11.6338 10.6956 11.4237 10.5259 11.2542C10.3564 11.0847 10.1463 11 9.89566 11H8.37641C8.25858 11 8.15541 11.0442 8.06691 11.1327C7.97841 11.2211 7.93416 11.3242 7.93416 11.4422V15.4422C7.93416 15.5602 7.97841 15.6634 8.06691 15.7518C8.15541 15.8403 8.25858 15.8845 8.37641 15.8845H9.89566ZM8.81866 15V11.8845H9.89566V15H8.81866ZM12.7804 13.8845H13.5304C13.6482 13.8845 13.7514 13.8402 13.8399 13.7517C13.9284 13.6634 13.9727 13.5602 13.9727 13.4422C13.9727 13.3242 13.9284 13.2211 13.8399 13.1327C13.7514 13.0442 13.6482 13 13.5304 13H12.7804V11.8845H13.5304C13.6482 11.8845 13.7514 11.8403 13.8399 11.7517C13.9284 11.6634 13.9727 11.5602 13.9727 11.4422C13.9727 11.3242 13.9284 11.2211 13.8399 11.1327C13.7514 11.0442 13.6482 11 13.5304 11H12.3379C12.2201 11 12.1169 11.0442 12.0284 11.1327C11.9399 11.2211 11.8957 11.3242 11.8957 11.4422V15.4422C11.8957 15.5602 11.9399 15.6634 12.0284 15.7518C12.1169 15.8403 12.2201 15.8845 12.3379 15.8845C12.4559 15.8845 12.5592 15.8403 12.6477 15.7518C12.7362 15.6634 12.7804 15.5602 12.7804 15.4422V13.8845Z" fill="#424242"/>
              <path d="M2.55 19.4556C2.05 19.4556 1.625 19.2806 1.275 18.9306C0.925 18.5806 0.75 18.1556 0.75 17.6556V2.25557C0.75 1.75557 0.925 1.33057 1.275 0.980567C1.625 0.630567 2.05 0.455566 2.55 0.455566H11.2461C11.4961 0.455566 11.7294 0.501233 11.9461 0.592566C12.1628 0.684566 12.3544 0.8139 12.5211 0.980567L16.7211 5.18057C16.8878 5.34723 17.0171 5.5389 17.1091 5.75557C17.2004 5.97223 17.2461 6.20557 17.2461 6.45557V17.6556C17.2461 18.1556 17.0711 18.5806 16.7211 18.9306C16.3711 19.2806 15.9461 19.4556 15.4461 19.4556H2.55ZM11.2461 1.95557H2.55C2.48333 1.95557 2.41667 1.9889 2.35 2.05557C2.28333 2.12223 2.25 2.1889 2.25 2.25557V17.6556C2.25 17.7222 2.28333 17.7889 2.35 17.8556C2.41667 17.9222 2.48333 17.9556 2.55 17.9556H15.4461C15.5128 17.9556 15.5794 17.9222 15.6461 17.8556C15.7128 17.7889 15.7461 17.7222 15.7461 17.6556V6.45557H12.1461C11.8961 6.45557 11.6834 6.3679 11.5081 6.19257C11.3334 6.0179 11.2461 5.80557 11.2461 5.55557V1.95557Z" fill="#424242"/>
            </svg>
          </span>
          <span class="menu-name">PDF Forms </span>
        </div>
      </a>
    </li>
    </ul>
      <div class="side-bar-footer">
          <div class="side-bar-footer-info">
              <h3 class="side-bar-footer-text">Show less information</h3>
              <a #sideBar class="collapse-sidebar" (click)="toggleSideNav()" (window:resize)="onResize($event)" title="{{hideSideNav? 'Show more information': 'Show less information'}}" href="javascript:void(0)"><img src="assets/images/ic-show-less-info.svg" alt=""></a>
          </div>
      </div>
  </div>
  