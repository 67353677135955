import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/before-login/login/login.component';
import { MainComponent } from './components/main/main.component';
import { RouterDataResolver } from './services/router.resolver';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: MainComponent,
    resolve: { data: RouterDataResolver },
    children: [
      {
        path: "forms",
        loadChildren: () => import("./components/after-login/forms/dynamic-forms.module").then(t => t.DynamicFormsModule)
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
