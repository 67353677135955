import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AgenzeeAdminWeb';
  subscription: Subscription;
  showLoader = false;
  message = '';
  showNotificaiton = false;
  constructor(private router: Router, private sharedService: SharedService, private authService: AuthService) {
    
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.subscription = this.sharedService.loader.subscribe(obj => {
          setTimeout(() => this.showLoader = obj, 0)
        });
      }
    });
    this.subscription = this.sharedService.showToast.subscribe(obj => {
      if (obj) {
        setTimeout(() => this.message = obj, 0)
        setTimeout(() => this.message = "", 100 * 100)
      }
    });
  }
}
