import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '../../../../../models/forms/form-control';
import { FormPage } from '../../../../../models/forms/form-page';
import { Form } from '../../../../../models/forms/form';
import { CdkDragDrop, CdkDragEnd, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormsService } from '../../../../../api-services/forms.service';
import { strict } from 'assert';
import { FileUpload } from '../../../../../models/forms/file-upload';
import { ControlType } from '../../../../../models/forms/control-type';
import { FormControlProperties } from '../../../../../models/forms/form-control-properties';
import { ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import { QueryList } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-manage-form',
  templateUrl: './manage-form.component.html',
  styleUrls: ['./manage-form.component.css']
})
export class ManageFormComponent implements OnInit {

  form: Form = {} as Form;
  selectedElement: FormControl | null = null;
  propertyForm: FormGroup;
  draggingControl: ControlType | null = null;
  selectedPageIndex: number | null = null;
  selectedContentIndex: number | null = null;
  newOption: string = '';
  fileUpload: FileUpload = {} as FileUpload;
  controlTypes: ControlType[] = [] as ControlType[];
  customControlTypes: ControlType[] = [] as ControlType[];
  selectedControl: ControlType = {} as ControlType;

  constructor(private formService: FormsService, private cdr: ChangeDetectorRef) { }

  @ViewChildren('signatureCanvas') signatureCanvases!: QueryList<ElementRef<HTMLCanvasElement>>;
  signaturePads: SignaturePad[] = [];

  ngAfterViewChecked(): void {
    if (this.signatureCanvases.length && this.signaturePads.length !== this.signatureCanvases.length) {
      this.initializeSignaturePads();
    }
  }

  initializeSignaturePads(): void {
    this.signaturePads = this.signatureCanvases.toArray().map((canvas, index) => {
      return new SignaturePad(canvas.nativeElement, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)'
      });
    });
  }

  clearSignature(index: number): void {
    const signaturePad = this.signaturePads[index];
    if (signaturePad) {
      signaturePad.clear();
    }
  }

  ngOnInit(): void {
    var selectedCarrier = localStorage.getItem("selectedFileUpload") as string;
    this.fileUpload = JSON.parse(selectedCarrier);

    this.getControlType();

    if (this.fileUpload.formId <= 0) {
      this.getFormFields();
    }
    else {
      this.getFormById(this.fileUpload.formId);
    }
  }

  getControlType() {
    this.formService.getControlTypes().subscribe(response => {
      this.controlTypes = response;
      this.customControlTypes = this.controlTypes.filter(obj => obj.isCustomControl == true);
    });
  }

  getFormFields() {    
    this.formService.getFormFields(this.fileUpload).subscribe(response => {
      this.renderPages(response.pages);
    });
  }

  getFormById(formId) {
    this.formService.getFormDetailById(formId).subscribe(response => {
      setTimeout(() => {
        this.form = response;
        this.cdr.detectChanges();
      }, 0);
    });
  }

  renderPages(pages: FormPage[]) {
    this.form = {} as Form;
    this.form.formPages = pages; // Set the pages array
  }

  startDrag(controlType: ControlType, event: DragEvent) {
    this.draggingControl = controlType;
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', controlType.controlTypeName);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, pageIndex: number) {
    event.preventDefault();
    if (this.draggingControl) {
      const target = event.target as HTMLElement;
      const rect = target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      var page = this.form.formPages[pageIndex];

      const newControl = {
        controlTypeID: this.draggingControl.controlTypeID,
        controlType: this.draggingControl.controlTypeName,
        subControlType: this.mapControlToFieldType(this.draggingControl.controlTypeName),
        controlName: this.randomString(6),
      } as FormControl;

      const controlPropeties = [

      ] as FormControlProperties[];

      this.draggingControl.controlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'label':
            var label = this.draggingControl?.controlTypeName == 'Label' ? 'Label' : this.draggingControl?.controlTypeName == 'Button' ? 'Action' : ''
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, propertyValue: label, dataType: obj.dataType } as FormControlProperties);
            break;
          case 'left':
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, propertyValue: x.toString(), dataType: obj.dataType } as FormControlProperties);
            break;
          case 'top':
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, propertyValue: (y + 30).toString(), dataType: obj.dataType } as FormControlProperties);
            break;
          case 'width':
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, propertyValue: "100", dataType: obj.dataType } as FormControlProperties);
            break;
          case 'height':
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, propertyValue: "20", dataType: obj.dataType } as FormControlProperties);
            break;
          default:
            controlPropeties.push({ controlName: newControl.controlName, controlPropertyID: obj.controlPropertyID, propertyName: obj.propertyName, dataType: obj.dataType } as FormControlProperties);
            break;
        }
      });
      newControl.formControlProperties = controlPropeties;
      this.getStyle(page.height, newControl);
      page.formControls.push(newControl);
      this.draggingControl = null;
    }

  }

  randomString(length: number): string {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  onDragStart(event: DragEvent, pageIndex: number, contentIndex: number) {
    const dragData = JSON.stringify({ pageIndex, contentIndex });
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', dragData);
    }
  }

  deleteControl(pageIndex: number, contentIndex: number) {
    this.form.formPages[pageIndex].formControls.splice(contentIndex, 1);
  }

  mapControlToFieldType(subControlType: string): string {
    switch (subControlType) {
      case 'TextBox':
        return '/Tx';
      case 'CheckBox':
        return '/Btn';
      case 'Button':
        return 'Button';
      case 'Dropdown':
        return 'Dropdown';
      case 'Label':
        return 'Label';
      case 'Signature':
        return 'Signature';
      default:
        return '';
    }
  }

  manageForm() {
    this.form.fileUploadId = this.fileUpload.fileUploadId;
    this.form.carrierId = this.fileUpload.carrierId;
    this.updateProperties();
    this.formService.saveFormDetail(this.form).subscribe(response => {
      if (this.form.formId > 0) {
        this.getFormById(this.form.formId);
      }
      else if (response) {
        this.getFormById(response);
      }
           
    });
  }

  saveAndComplete() {
    this.form.fileUploadId = this.fileUpload.fileUploadId;
    this.updateProperties();
    this.formService.saveAndComplete(this.form).subscribe(response => {
        this.getFormById(this.form.formId);      
    });
  }

  updateProperties() {
    this.form.formPages.forEach(objPage => {
      objPage.formControls.forEach(objControl => {
        objControl.formControlProperties.forEach(objProp => {
          if (objProp.dataType == 'number') {
            objProp.propertyValue = objProp.propertyValue == null ? null : objProp.propertyValue.toString();
          }
          else if (objProp.dataType == 'bool'){
            objProp.propertyValue = objProp.propertyValue == null ? null : objProp.propertyValue.toString();
          }
        });
      });
    });
  }

  getPageStyle(page: FormPage) {
    var style = {};
    style['position'] = 'relative';
    style['height'] = page.height + 'px';
    style['width'] = page.width + 'px';
    style['border'] = '1px solid red';

    return style;
  }

  getStyle(pageHeight: number, content: FormControl) {
    var style = {};
    if (content.controlType == 'Image') {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['z-index'] = 2;
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
          case 'style':
            if (obj.propertyValue != null && obj.propertyValue.length > 0) {
              this.extractStyleProperties(style, obj.propertyValue);
            }
            break;
        }
      });
    }
    else if (content.controlType == 'FormField') {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['z-index'] = 2;
      style['border'] = this.selectedElement === content ? '1px solid red' : 'none';
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
          case 'fontsize':
            style['font-size'] = `${obj.propertyValue}px`;
            break;
          case 'fonttype':
            style['font-family'] = obj.propertyValue;
            break;
          case 'style':
            if (obj.propertyValue != null && obj.propertyValue.length > 0) {
              this.extractStyleProperties(style, obj.propertyValue);
            }
            break;
        }
      });
    }
    else if (content.controlType == 'TextArea') {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['z-index'] = 2;
      style['border'] = this.selectedElement === content ? '1px solid red' : 'none';
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
        }
      });
    }
    else if (content.controlType == 'Signature') {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['z-index'] = 2;
      style['border'] = this.selectedElement === content ? '1px solid red' : 'none';
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
        }
      });
    }
    else if (content.controlType == 'Box') {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['border'] = this.selectedElement === content ? '1px solid red' : '1px solid black';
      style['background-color'] = "transparent";
      style['z-index'] = 1;
      style['pointer-events'] = "auto";
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
        }
      });
    }
    else {
      style['position'] = 'absolute';
      style['cursor'] = 'pointer';
      style['z-index'] = 2;
      style['border'] = this.selectedElement === content ? '1px solid red' : 'none';
      content.formControlProperties.forEach(obj => {
        switch (obj.propertyName.toLowerCase()) {
          case 'left':
            style['left'] = `${obj.propertyValue}px`;
            break;
          case 'top':
            style['top'] = `${parseFloat(obj.propertyValue ?? '0')}px`;
            break;
          case 'width':
            style['width'] = `${obj.propertyValue}px`;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
          //case 'fontstyle':
          //  style['font-style'] = obj.propertyValue != null && obj.propertyValue.includes('Italic') ? 'italic' : 'normal';
          //  style['font-weight'] = obj.propertyValue != null && obj.propertyValue.includes('Italic') ? 'italic' : 'normal';
          //  break;
          case 'fontsize':
            style['font-size'] = `${obj.propertyValue}px`;
            break;
          case 'fonttype':
            style['font-family'] = obj.propertyValue;
            break;
          case 'height':
            style['height'] = `${obj.propertyValue}px`;
            break;
          case 'style':
            if (obj.propertyValue != null && obj.propertyValue.length > 0) {
              this.extractStyleProperties(style, obj.propertyValue);
            }
            break;
        }
      });
    }
    return style;
  };

  extractStyleProperties(style, propertyValue) {
    const styleArray = propertyValue.split(";").filter(s => s.trim() !== "");
    styleArray.forEach(_style => {
      const [key, value] = _style.split(":").map(s => s.trim());
      const camelCaseKey = key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
      style[camelCaseKey] = value;
    });
  }

  selectChunk(ele: any, pageIndex: number, contentIndex: number): void {   

    var _controlType = this.controlTypes.find(obj => obj.controlTypeID == ele?.controlTypeID);
    if (_controlType != null) {
      this.selectedElement = ele;
      this.selectedControl = _controlType;
      this.selectedPageIndex = pageIndex;
      this.selectedContentIndex = contentIndex;
    }
  }

  onDivClick(ele: any, pageIndex: number, contentIndex: number): void {

    var _controlType = this.controlTypes.find(obj => obj.controlTypeID == ele?.controlTypeID);
    if (_controlType != null) {
      this.selectedElement = ele;
      this.selectedControl = _controlType;
      this.selectedPageIndex = pageIndex;
      this.selectedContentIndex = contentIndex;
    }
  }

  updateChunk(): void {
    if (this.selectedElement) {
      this.selectedElement = null;
    }
  }

  getElementPropertyValue(ele: FormControl, propertyName: string) {
    var property = ele?.formControlProperties.find(p => p.propertyName.toLowerCase() === propertyName.toLowerCase());
    if (property != null && property.propertyValue != undefined) {
      return property.propertyValue
    }
    else {
      return null;
    }
  }

  getFormControlPropertyValue(propertyName: string): string {
    const property = this.selectedElement?.formControlProperties.find(p => p.propertyName.toLowerCase() === propertyName.toLowerCase());
    return property ? (property.propertyValue == null || property.propertyValue == undefined) ? '' : property.propertyValue : '';
  }

  setFormControlPropertyValue(propertyName: string, value: string) {
    const property = this.selectedElement?.formControlProperties.find(p => p.propertyName.toLowerCase() === propertyName.toLowerCase());
    if (property) {
      property.propertyValue = value;
    }
  }

  closeModal(): void {
    this.selectedElement = null;
  }

  addDropdownOption(pageIndex: number, contentIndex: number, option: string) {
    //if (this.form.formPages[pageIndex].formControls[contentIndex].options == undefined) {
    //  this.form.formPages[pageIndex].formControls[contentIndex].options = [];
    //}
    //this.form.formPages[pageIndex].formControls[contentIndex].options.push(option);
  }

  executeAction(action: string) {
    // Execute dynamic action
    alert(`Executing action: ${action}`);
  }


}
