import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AccountService } from '../../../api-services/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name = localStorage['name'];
  shortName: any
  
  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    public router: Router) {
    
  }


  ngOnInit(): void {
    this.shortName = this.name.split(' ').map((n: any[]) => n[0]).join('');
    
  }

  logout(): void {
    this.accountService.userLogOut().subscribe(
      (response: any) => {
        console.log("User logged out successfully");
      },
      (error: any) => {
        console.log(error);
      }
    )

    this.accountService.logout();
    this.router.navigate(['/login']);
  }

}
