

<div id="pdf-container" class="p-3">
  <form #manageform="ngForm" (ngSubmit)="manageForm()">
    <div class="mb-4">
      <div class="row">
        <div class="col-3 pr-2">
          <div class="input-block">
            <label for="name" class="input-name">Name:</label><span class="validation-field">*</span>
            <input id="name" class="form-control" [(ngModel)]="form.formName" name="formName" required>
          </div>
        </div>
        <div class="col-6">
          <div class="input-block">
            <label for="text" class="input-name">Description:</label>
            <input id="text" class="form-control" [(ngModel)]="form.description" name="description">
          </div>
        </div>
        <div class="col-3 pl-2" *ngIf="form?.fileUploadStatusId != 3">
          <div class="d-flex mt-4 justify-content-end">
            <button type="submit" class="btn primary-btn btn-sm mr-2" [disabled]="!manageform.valid">Save</button>
            <button type="button" class="btn primary-btn btn-sm" [disabled]="!manageform.valid" (click)="saveAndComplete()">Save & Complete</button>
          </div>
        </div>
      </div>
    </div>
  </form>


  <div class="row">
    <div class="col-3 pr-2">
      <div class="property-window">
        <h3>Form Controls</h3>
        <div class="row">
          <div class="col-md-6 col-12" *ngFor="let control of customControlTypes; let i = index">
            <div id="toolbox">
              <div class="toolbox-item" draggable="true" (dragstart)="startDrag(control, $event)">
                <div>
                  <img src="assets/images/{{control.controlTypeName | lowercase}}.svg" alt="">
                  <p class="mb-0" style="display: block;">
                    {{control.controlTypeName}}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 px-2 form-height">
      <div *ngFor="let page of form.formPages; let i = index"
           class="pdf-page"
           [ngStyle]="getPageStyle(page)"
           (drop)="onDrop($event, i)" (dragover)="onDragOver($event)">
        <div *ngFor="let contentItem of page.formControls; let j = index"
             class="pdf-element"
             [ngStyle]="getStyle(page.height, contentItem)">
          <ng-container [ngSwitch]="contentItem.controlType">
            <div *ngSwitchCase="'Text'" class="form-field-container" (click)="selectChunk(contentItem,i,j)">
              <div> {{getElementPropertyValue(contentItem,'label') }}</div>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'Image'" class="form-field-container" (click)="selectChunk(contentItem,i,j)">
              <img (click)="selectChunk(contentItem,i,j)" [src]="'data:image/jpeg;base64,' + getElementPropertyValue(contentItem,'src')" [style.width.%]="'100'" [style.height.%]="'100'">
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'FormField'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <input *ngIf="contentItem.subControlType === '/Tx'" class="input-dynamic" type="text" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName">
              <input *ngIf="contentItem.subControlType === '/Btn'" class="input-dynamic" type="checkbox" [checked]="getElementPropertyValue(contentItem,'defaultValue') === 'true'" [name]="contentItem.controlName">
              <select *ngIf="contentItem.subControlType === '/Ch'" class="input-dynamic" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName"></select>
              <div *ngIf="contentItem.subControlType === 'Label'"> {{ getElementPropertyValue(contentItem,'label') }}</div>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'Label'" class="form-field-container" (click)="selectChunk(contentItem,i,j)">
              <div> {{getElementPropertyValue(contentItem,'label') }}</div>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'TextBox'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <input class="input-dynamic" type="text" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName">
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'CheckBox'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <input class="input-dynamic" type="checkbox" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName">
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'DropDown'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <select class="input-dynamic" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName">
                <option *ngFor="let option of getElementPropertyValue(contentItem,'options')">{{ option }}</option>
              </select>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'RadioButton'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <input class="input-dynamic" type="radio" [value]="getElementPropertyValue(contentItem,'defaultValue')" [name]="contentItem.controlName">
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'TextArea'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <textarea class="input-dynamic" [cols]="getElementPropertyValue(contentItem,'cols')" [rows]="getElementPropertyValue(contentItem,'rows')" [name]="contentItem.controlName">{{getElementPropertyValue(contentItem,'defaultValue')}}</textarea>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
            <div *ngSwitchCase="'Box'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <div [style.width.px]="getElementPropertyValue(contentItem,'width')"
              [style.height.px]="getElementPropertyValue(contentItem,'height')" style="z-index: 9; position: relative;"></div>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>

            <div *ngSwitchCase="'Signature'" class="form-field-container" draggable="true" (dragstart)="onDragStart($event, i, j)" (click)="selectChunk(contentItem,i,j)">
              <div class="signature-container">
                <canvas #signatureCanvas class="signature-canvas"
                        [width]="getElementPropertyValue(contentItem,'width')"
                        [height]="getElementPropertyValue(contentItem,'height')"></canvas>
                <button class="btn basic-btn btn-sm" style="padding: 5px 10px;" type="button" (click)="clearSignature(i)">Clear</button>
              </div>
              <button class="delete-button" (click)="deleteControl(i, j)">X</button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-3 pl-2">
      <div id="propertyModal" *ngIf="selectedElement" class="property-window">
        <h3>Edit Properties</h3>
        <form>
          <div *ngFor="let property of selectedElement.formControlProperties;let i = index;">
            <div>
              <label class="input-name">{{property.propertyName}}:</label>

              <input *ngIf="property.dataType === 'string'" class="form-control"
                     [(ngModel)]="property.propertyValue" [ngModelOptions]="{standalone: true}"
                     [attr.name]="property.propertyName" />

              <input *ngIf="property.dataType === 'number'" class="form-control"
                     type="number"
                     [(ngModel)]="property.propertyValue" [ngModelOptions]="{standalone: true}"
                     [attr.name]="property.propertyName" />

              <input *ngIf="property.dataType === 'bool'" class="form-control"
                     type="checkbox"
                     [(ngModel)]="property.propertyValue" [ngModelOptions]="{standalone: true}"
                     [attr.name]="property.propertyName" />

            </div>
          </div>
          <button class="btn primary-btn btn-sm ml-auto my-2" (click)="closeModal()">Close</button>
        </form>

      </div>
    </div>
  </div>
</div>




