import { Component, OnInit } from '@angular/core';
import { FileUpload } from '../../../../models/forms/file-upload'
import { FormsService } from '../../../../api-services/forms.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-forms',
  templateUrl: './list-forms.component.html',
  styleUrls: ['./list-forms.component.css']
})
export class ListFormsComponent implements OnInit {

  fileUploads: FileUpload[] = [] as FileUpload[];

  constructor(private formService: FormsService, private router: Router,
    ) { }

  ngOnInit(): void {
    this.getCarrierUploads();
  }

  getCarrierUploads() {
    this.formService.getFileUploads().subscribe(response => {
      this.fileUploads = response as FileUpload[];
    })
  }

  selectForm(fileUpload: FileUpload) {
    localStorage.setItem("selectedFileUpload", JSON.stringify(fileUpload));
    this.router.navigate(["/forms/manage"]);
  }

  generatePdf(fileUpload: FileUpload) {
    this.formService.generateFile(fileUpload.formId).subscribe(response => {
      let byteCharacters = atob(response.response);
      let byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++)
        byteNumbers[i] = byteCharacters.charCodeAt(i);

      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: response.contentType });

      if (response.contentType === "application/pdf") {
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }
      else {
        var blobURL = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = response.fileName;
        anchor.href = blobURL;
        anchor.click();
      }
    }),
      (error: any) => {
        console.log(error);
      };
  }

}
