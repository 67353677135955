<div class="common-table" style="border-top: 1px solid #e0e0e0 ; padding-top: 0px; overflow-y: auto;" >
  <table class="table">
    <thead class="sticky-from-top" style="top: 0;">
      <tr class="text-nowrap">
        <th scope="col">File Name</th>
        <th scope="col">Status</th>
        <th scope="col">Created By</th>
        <th scope="col">Created On</th>
        <th scope="col">Manage</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fileUpload of fileUploads;let i = index">
        <td>{{fileUpload.fileName}}</td>
        <td>{{fileUpload.fileUploadStatus}}</td>
        <td>{{fileUpload.createdByName}}</td>
        <td>{{fileUpload.createdOn | date: 'MM/dd/yyyy hh:mm a'}}</td>
        <td>
          <div class="status p-0 d-flex">
            <div class="pr-2">
              <a href="javascript:void(0)" class="mail-icon type" (click)="selectForm(fileUpload)">Select</a>
            </div>
            <div class="">
              <a href="javascript:void(0)" class="mail-icon type" (click)="generatePdf(fileUpload)">Generate</a>
            </div>
          </div>
          
        </td>
      </tr>
    </tbody>
  </table>
</div>



