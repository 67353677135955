import { Injectable } from '@angular/core';
import { Login } from '../models/login.model';
import { AuthService } from '../services/auth.service';
import { ApiClientService } from './common/api-client.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private apiClient: ApiClientService, private authService: AuthService) { }

  public loginUser(login: Login) {
    return this.apiClient.post('api/admin/login/userLogin', login, null);
  }

  public setToken(tokenresponse: any, token: string) {
    this.authService.userLoggedIn(tokenresponse);
    this.apiClient.setHeader('Authorization', `bearer ${token}`);
  }

  public logout() {
    this.authService.userLoggedOut();
  }

  public userLogOut() {
    return this.apiClient.post(`api/admin/login/userLogOut`, 1, null, false);
  }
}
