import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../auth/auth.guard';
import { RoleGuard } from '../../../auth/role.guard';
import { rights } from '../../../models/rights.enum';
import { ListFormsComponent } from './list-forms/list-forms.component';
import { ManageFormComponent } from './list-forms/manage-form/manage-form.component';

const routes: Routes = [
  {
    path: 'list',
    component: ListFormsComponent,
    data: {
      expectedRights: [rights.systemadmin, rights.formsadmin]
    },
    canActivate: [AuthGuard, RoleGuard]
  },
  {
    path: 'manage',
    component: ManageFormComponent,
    data: {
      expectedRights: [rights.systemadmin, rights.formsadmin]
    },
    canActivate: [AuthGuard, RoleGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicFormsRoutingModule { }
