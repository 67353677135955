<header>
  <div class="header-content">
    <div class="container-fluid">
      <div class="display-flex center-div">
        <div class="logo-sec">
          <div class="left-part">
            <div style="cursor: pointer;" class="logo">
              <img class="main-logo" src="assets/images/logo-text.svg" alt="Agenzee" title="Agenzee"/>
              <img class="second-logo" src="assets/images/logo-icon.png" alt="Agenzee" title="Agenzee">
            </div>
          </div>
        </div>
        <div class="nav-sec">
          <div class="right-part">
            <div class="page-title">
              <h1 class="page-name">
                <span class="page-title-icon">
                  <!-- <img src="assets/images/ic-contacts.svg" class="dash-icon icons active" alt=""/>  -->
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.91491 13.8845H6.03041C6.28091 13.8845 6.49099 13.7998 6.66066 13.6303C6.83016 13.4608 6.91491 13.2507 6.91491 13V11.8845C6.91491 11.6338 6.83016 11.4237 6.66066 11.2542C6.49099 11.0847 6.28091 11 6.03041 11H4.47266C4.35466 11 4.25141 11.0442 4.16291 11.1327C4.07458 11.2211 4.03041 11.3242 4.03041 11.4422V15.4422C4.03041 15.5602 4.07458 15.6634 4.16291 15.7518C4.25141 15.8403 4.35466 15.8845 4.47266 15.8845C4.59066 15.8845 4.69383 15.8403 4.78216 15.7518C4.87066 15.6634 4.91491 15.5602 4.91491 15.4422V13.8845ZM4.91491 13V11.8845H6.03041V13H4.91491ZM9.89566 15.8845C10.1463 15.8845 10.3564 15.7997 10.5259 15.6302C10.6956 15.4607 10.7804 15.2507 10.7804 15V11.8845C10.7804 11.6338 10.6956 11.4237 10.5259 11.2542C10.3564 11.0847 10.1463 11 9.89566 11H8.37641C8.25858 11 8.15541 11.0442 8.06691 11.1327C7.97841 11.2211 7.93416 11.3242 7.93416 11.4422V15.4422C7.93416 15.5602 7.97841 15.6634 8.06691 15.7518C8.15541 15.8403 8.25858 15.8845 8.37641 15.8845H9.89566ZM8.81866 15V11.8845H9.89566V15H8.81866ZM12.7804 13.8845H13.5304C13.6482 13.8845 13.7514 13.8402 13.8399 13.7517C13.9284 13.6634 13.9727 13.5602 13.9727 13.4422C13.9727 13.3242 13.9284 13.2211 13.8399 13.1327C13.7514 13.0442 13.6482 13 13.5304 13H12.7804V11.8845H13.5304C13.6482 11.8845 13.7514 11.8403 13.8399 11.7517C13.9284 11.6634 13.9727 11.5602 13.9727 11.4422C13.9727 11.3242 13.9284 11.2211 13.8399 11.1327C13.7514 11.0442 13.6482 11 13.5304 11H12.3379C12.2201 11 12.1169 11.0442 12.0284 11.1327C11.9399 11.2211 11.8957 11.3242 11.8957 11.4422V15.4422C11.8957 15.5602 11.9399 15.6634 12.0284 15.7518C12.1169 15.8403 12.2201 15.8845 12.3379 15.8845C12.4559 15.8845 12.5592 15.8403 12.6477 15.7518C12.7362 15.6634 12.7804 15.5602 12.7804 15.4422V13.8845Z" fill="#0068D8"/>
                    <path d="M2.55 19.4556C2.05 19.4556 1.625 19.2806 1.275 18.9306C0.925 18.5806 0.75 18.1556 0.75 17.6556V2.25557C0.75 1.75557 0.925 1.33057 1.275 0.980567C1.625 0.630567 2.05 0.455566 2.55 0.455566H11.2461C11.4961 0.455566 11.7294 0.501233 11.9461 0.592566C12.1628 0.684566 12.3544 0.8139 12.5211 0.980567L16.7211 5.18057C16.8878 5.34723 17.0171 5.5389 17.1091 5.75557C17.2004 5.97223 17.2461 6.20557 17.2461 6.45557V17.6556C17.2461 18.1556 17.0711 18.5806 16.7211 18.9306C16.3711 19.2806 15.9461 19.4556 15.4461 19.4556H2.55ZM11.2461 1.95557H2.55C2.48333 1.95557 2.41667 1.9889 2.35 2.05557C2.28333 2.12223 2.25 2.1889 2.25 2.25557V17.6556C2.25 17.7222 2.28333 17.7889 2.35 17.8556C2.41667 17.9222 2.48333 17.9556 2.55 17.9556H15.4461C15.5128 17.9556 15.5794 17.9222 15.6461 17.8556C15.7128 17.7889 15.7461 17.7222 15.7461 17.6556V6.45557H12.1461C11.8961 6.45557 11.6834 6.3679 11.5081 6.19257C11.3334 6.0179 11.2461 5.80557 11.2461 5.55557V1.95557Z" fill="#1B6FD1"/>
                  </svg>  
                </span>
                <span class="name">PDF Forms</span>
              </h1>
            </div>
            <div class="user-data">
              <nav class="navbar navbar-expand-sm header-user-info-block">          
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="header-user-dropdown">
                  <ul class="navbar-nav">
                    
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle user-short-name-link" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="https://ui-avatars.com/api/?name={{shortName|uppercase}}&rounded=true&background=FFD54F&font-size=0.35&size=40&bold=true" alt="user-icon">
                      </a>
                      <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item logout" href="javascript:void()" (click)="logout()"><span class="user-dropdown"><img src="assets/images/ic-logout.svg" alt="" /></span>Log Out</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
</header>
