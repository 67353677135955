import { Component, OnInit, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Login } from './../../../models/login.model';
import { AccountService } from './../../../api-services/account.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { SharedService } from './../../../services/shared.service';

import { CommonFunc } from './../../çommon/common-func';
import { ValidatorHelper } from '../../../custom-validators/validators.helper';

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  errorMessages = [] as string[];
  loginForm!: FormGroup;
  subscription: Subscription[] = [];
  renderUI = true;
  public userLogin: Login = {} as Login;
  isInputVal: boolean = false;
  isPasswordVal: boolean = false;
  isShow: boolean = false;
  constructor(private router: Router, private fb: FormBuilder,
    private accountService: AccountService, private sharedService: SharedService,
    private activateRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, ValidatorHelper.emailPattern]],
      password: ['', [Validators.required]],
      platformId: 1
    });
  }

  ngOnChanges(changes: SimpleChange) {
    var get_input_form = this.loginForm.value;
    if (get_input_form.email != '') {
      this.isInputVal = true;
    }
    else if (get_input_form.password != '') {
      this.isPasswordVal = true;

    }
  }
  onSubmit(): void {
    this.errorMessages = [];
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      this.subscription.push(this.accountService.loginUser(this.loginForm.value).subscribe(
        (response: any) => {
          this.errorMessages = [];

          this.accountService.setToken(response, response.token);

          this.router.navigate(["/forms/list"]);
          
        },
        (error: any) => {
          this.errorMessages = [];
          this.errorMessages.push("Email or password incorrect.")
        }
      ))
    }
  }

  showPassword(id) {
    this.isShow = !this.isShow;
    CommonFunc.showPassword(id);
  }


}

