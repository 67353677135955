import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUpload } from '../models/forms/file-upload';
import { ControlType } from '../models/forms/control-type';
import { Form } from '../models/forms/form';
import { FormPage } from '../models/forms/form-page';
import { ApiClientService } from './common/api-client.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor(private apiClient: ApiClientService, private http: HttpClient) { }

  getFileUploads(): Observable<FileUpload[]> {
    return this.apiClient.get(`api/admin/forms/getFileUploads`, null, null, true);
  }

  getFormDetailById(formId: number): Observable<Form> {
    return this.apiClient.get(`api/admin/forms/getFormDetailById/${formId}`, null, null, true);
  }

  saveFormDetail(form: Form) {
    return this.apiClient.post(`api/admin/forms/saveForm`, form, null, true);
  }

  saveAndComplete(form: Form) {
    return this.apiClient.post(`api/admin/forms/saveAndCompleteForm`, form, null, true);
  }

  getFormFields(fileUpload: FileUpload) {
    return this.apiClient.post(`api/admin/forms/getFormFields`, fileUpload, null, true);
  }

  getControlTypes(): Observable<ControlType[]> {
    return this.apiClient.get(`api/admin/forms/getControlTypes`, null, null, true);
  }

  generateFile(formId) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
      })
    };

    var requestUrl = environment.baseUrl + `api/admin/forms/generatePdf/${formId}`;

    return this.http.get(requestUrl, httpOptions)
      .pipe(map((response: any) => {
        let data = response;
        return data;
      }
      ));
  }
}
