import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { jwtDecode } from 'jwt-decode';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(public router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    var isUserLoggedIn = this.authService.isUserLoggedIn.getValue();
    if (!isUserLoggedIn) {
      sessionStorage.clear();
      this.router.navigate(['/login']);
      return false;
    }

    let expectedRightData = route.data;
    let expectedRightArray: number[] = expectedRightData['expectedRights'];

    const token = localStorage.getItem('token');

    if (!token) {
      return false;
    }

    // decode the token to get its payload
    const tokenPayload = jwtDecode(token);

    let expectedRole = false;

    if (expectedRightArray && expectedRightArray.length) {
      for (let i = 0; i < expectedRightArray.length; i++) {
        if (expectedRightArray[i] & tokenPayload['rights']) {
          expectedRole = true;
        }
      }
    }
    if (token && expectedRole) {
      return true;
    }

    this.router.navigate(['/page-not-found']);
    return false;
  }
}
