<div class="main-layout-grid" *ngIf="renderUI">
  <main id="main-container">
    <div class="data-content custom-height-center justify-content-center">
      <div class="content-width right">
        <div class="right-side-content before-login-page">
          <div class="container">
            <div style="cursor: pointer;" class="logo text-center pt-4">
              <img class="main-logo" src="assets/images/logo-text.svg" alt="Agenzee" title="Agenzee"/>
            </div>
            <div class="form-details">
              <div class="forms-data">
                <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="form-block-title">
                    <h3 class="form-title">Login</h3>
                    <div class="form-inputs-data">
                      <div class="form-group" [class.error]="((loginForm.get('email')!.touched ||
                      loginForm.get('email')!.dirty) &&
                      loginForm.get('email')!.errors)">

                        <input autocomplete="off" type="text" maxlength="50"
                               [class.not-empty]="!loginForm.get('email')!.valid && (((loginForm.get('email')!.touched ||
                                                  loginForm.get('email')!.dirty) && loginForm.get('email')!.errors))"
                               formControlName="email" class="form-control form-input" [ngClass]="{'not-empty-val':isInputVal}" name="loginemail"
                               id="loginEmail" placeholder=" " required>
                        <label class="form-label">Email  <span class="validation-field">*</span></label>
                        <div class="form-icon">
                          <svg class="email-icon" data-name="icons / ic-mail"
                               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path class="fill-color"
                                  d="M12,21.5a9.263,9.263,0,0,1-3.712-.75A9.432,9.432,0,0,1,3.25,15.712,9.263,9.263,0,0,1,2.5,12a9.24,9.24,0,0,1,.75-3.725A9.466,9.466,0,0,1,8.288,3.25,9.263,9.263,0,0,1,12,2.5a9.24,9.24,0,0,1,3.725.75A9.5,9.5,0,0,1,20.75,8.275,9.24,9.24,0,0,1,21.5,12v1.225a3.183,3.183,0,0,1-.938,2.325,3.119,3.119,0,0,1-2.312.95,3.141,3.141,0,0,1-2.825-1.625,5.11,5.11,0,0,1-1.462,1.175A4.089,4.089,0,0,1,12,16.5,4.48,4.48,0,0,1,7.5,12,4.48,4.48,0,0,1,12,7.5,4.48,4.48,0,0,1,16.5,12v1.225a1.75,1.75,0,1,0,3.5,0V12a7.7,7.7,0,0,0-2.363-5.638A7.7,7.7,0,0,0,12,4,7.7,7.7,0,0,0,6.362,6.362,7.7,7.7,0,0,0,4,12a7.7,7.7,0,0,0,2.362,5.637A7.7,7.7,0,0,0,12,20h4.25a.75.75,0,1,1,0,1.5ZM12,15a2.988,2.988,0,0,0,3-3,2.988,2.988,0,0,0-3-3,2.988,2.988,0,0,0-3,3,2.988,2.988,0,0,0,3,3Z"
                                  transform="translate(-0.5 -0.5)" fill="#757575" />
                          </svg>
                        </div>
                        <app-error [controlName]="loginForm.controls['email']">
                        </app-error>
                      </div>
                      <div class="form-group" [class.error]="((loginForm.get('password')!.touched ||
                      loginForm.get('password')!.dirty) &&
                      loginForm.get('password')!.errors)">
                        <input autocomplete="new-password" type="password" [ngClass]="{'not-empty-val':isPasswordVal}" formControlName="password" maxlength="50" class="form-control form-input"
                               name="loginpassword" id="loginPassword" placeholder=" " required>
                        <label class="form-label">Password <span class="validation-field">*</span></label>
                        <div class="form-icon" (click)="showPassword('loginPassword')">
                          <svg class="pass-icon open-eye" [class.hidden]="!isShow" id="icons_ic-visibility" data-name="icons / ic-visibility"
                               xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path class="fill-color"
                                  d="M12,15.575A4.056,4.056,0,0,0,16.075,11.5,4.056,4.056,0,0,0,12,7.425,4.056,4.056,0,0,0,7.925,11.5,4.056,4.056,0,0,0,12,15.575ZM12,14.2a2.69,2.69,0,0,1-2.7-2.7A2.689,2.689,0,0,1,12,8.8a2.687,2.687,0,0,1,2.7,2.7A2.689,2.689,0,0,1,12,14.2Zm0,4.3a11.058,11.058,0,0,1-6.013-1.725A11.311,11.311,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263,2.291,2.291,0,0,1,0-.675A.9.9,0,0,1,1.8,10.9,11.311,11.311,0,0,1,5.987,6.225,11.058,11.058,0,0,1,12,4.5a11.053,11.053,0,0,1,6.012,1.725A11.308,11.308,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.308,11.308,0,0,1-4.188,4.675A11.053,11.053,0,0,1,12,18.5Zm0-7ZM12,17a9.545,9.545,0,0,0,5.188-1.488A9.771,9.771,0,0,0,20.8,11.5a9.777,9.777,0,0,0-3.612-4.013A9.549,9.549,0,0,0,12,6,9.549,9.549,0,0,0,6.812,7.487,9.777,9.777,0,0,0,3.2,11.5a9.771,9.771,0,0,0,3.612,4.012A9.545,9.545,0,0,0,12,17Z"
                                  transform="translate(0 0.5)" fill="#757575" />
                          </svg>
                          <svg [class.hidden]="isShow" class="pass-icon eye-close" data-name="icons / ic-visibility" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <rect data-name="Rectangle 454" width="24" height="24" fill="none" />
                            <path d="M15.775,12.975,14.65,11.85a2.469,2.469,0,0,0-.712-2.237A2.431,2.431,0,0,0,11.65,8.85L10.525,7.725a3.794,3.794,0,0,1,.7-.225A3.706,3.706,0,0,1,12,7.425,4.056,4.056,0,0,1,16.075,11.5a3.881,3.881,0,0,1-.075.787A3.163,3.163,0,0,1,15.775,12.975Zm3.175,3.1-1.1-1.025a10.956,10.956,0,0,0,1.688-1.588A8.9,8.9,0,0,0,20.8,11.5a9.832,9.832,0,0,0-3.588-4.013A9.507,9.507,0,0,0,12,6a10.064,10.064,0,0,0-1.425.1A9.625,9.625,0,0,0,9.2,6.4L8.025,5.225a10.032,10.032,0,0,1,1.938-.55A11.648,11.648,0,0,1,12,4.5a10.967,10.967,0,0,1,6.062,1.762A11.327,11.327,0,0,1,22.2,10.9a.9.9,0,0,1,.075.262,2.291,2.291,0,0,1,0,.675.9.9,0,0,1-.075.263,11.329,11.329,0,0,1-1.388,2.175A10.884,10.884,0,0,1,18.95,16.075Zm.275,5.275-3.5-3.5a9.647,9.647,0,0,1-1.712.475A10.976,10.976,0,0,1,12,18.5a10.951,10.951,0,0,1-6.075-1.762A11.357,11.357,0,0,1,1.8,12.1a.9.9,0,0,1-.075-.263A2.208,2.208,0,0,1,1.7,11.5a1.98,1.98,0,0,1,.025-.325A1.326,1.326,0,0,1,1.8,10.9,11.315,11.315,0,0,1,3.175,8.775,12.052,12.052,0,0,1,4.9,7.05L2.65,4.775a.72.72,0,0,1-.225-.525A.8.8,0,0,1,2.65,3.7a.782.782,0,0,1,.538-.2.693.693,0,0,1,.512.2L20.3,20.3a.725.725,0,0,1,.213.5.757.757,0,0,1-.213.55.822.822,0,0,1-1.075,0ZM5.95,8.1A10.06,10.06,0,0,0,4.413,9.613,8.946,8.946,0,0,0,3.2,11.5a9.828,9.828,0,0,0,3.587,4.012A9.507,9.507,0,0,0,12,17a8.163,8.163,0,0,0,1.35-.113q.675-.112,1.15-.237l-1.25-1.3a3.887,3.887,0,0,1-.6.162,3.408,3.408,0,0,1-.65.063A4.056,4.056,0,0,1,7.925,11.5a3.489,3.489,0,0,1,.063-.638,3.81,3.81,0,0,1,.162-.612Zm7.575,2.625Zm-3.3,1.65Z" transform="translate(0 0.525)" fill="#757575" />
                          </svg>
                        </div>

                        <app-error [controlName]="loginForm.controls['password']">
                        </app-error>
                      </div>
                      <app-validation-summary [validationErrors]="errorMessages"></app-validation-summary>
                    </div>

                    <div class="action-btn">
                      <button type="submit" class="btn commn-btn login-btn width-100" [disabled]="!loginForm.valid">
                        <span class="login-btn-text">Login</span><span class="login-icon"></span>
                      </button>                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
