import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RouterDataResolver implements Resolve<any> {
  pageName: any;
  breadcrumb: any;
  pageIcon: any;
  hideSideBar: any;
  showIcon: any;
  constructor(

  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return of('Route!').pipe(
      tap(res => {
        this.pageName = route.data['title'];
        this.breadcrumb = route.data['breadcrumb'];
        this.pageIcon = route.data['icon'];
        this.hideSideBar = route.data['sideBar'];
        this.showIcon = route.data['showIcon'];
      })
    );;
  }
}
