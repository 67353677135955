import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public loader: BehaviorSubject<any> = new BehaviorSubject(false);
  public showToast: BehaviorSubject<any> = new BehaviorSubject('');
  public isRefreshRequired: BehaviorSubject<any> = new BehaviorSubject(false);
  
  public loaderCount: number = 0;
  constructor() { }

  showLoader() {
    if (this.loaderCount < 0) {
      this.loaderCount = 0;
    }
    this.loaderCount++;
    this.loader.next(true);
  }

  showToastMessage(message: string) {
    this.showToast.next(message);
  }

  hideLoader() {
    this.loaderCount--;
    if (this.loaderCount <= 0) {
      this.loader.next(false);
    }
  }
}
